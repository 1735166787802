// If you want to override variables do it here
@import "variables";
@import "colors";

// Import CoreUI styles
@import "~@coreui/coreui/scss/coreui.scss";

// Some temp fixes
@import "fixes";
.h-full {
  height: 100%;
}
.w-full {
  width: 100%;
}
.h-screen {
  height: 100vh;
}
.w-screen {
  width: 100vh;
}
.w-fit {
  width: fit-content;
}
.h-fit {
  height: fit-content;
}
.w-50 {
  width: 50%;
}
.absolute {
  position: absolute;
}

.z-10 {
  z-index: 10;
}

.z-20 {
  z-index: 20;
}

.relative {
  position: relative;
}
.hidden {
  display: none;
}

.right {
  float: right;
}

.text-black {
  color: #000 !important;
}

.uppercase {
  text-transform: uppercase;
}

.text-xs {
  font-size: 12px;
}

.text-xl {
  font-size: 1.5rem;
}

.flex {
  display: flex;
}

.flex-col {
  display: flex;
  flex-direction: column;
}

.items-start {
  align-items: flex-start;
}

.items-center {
  align-items: center;
}

.items-end {
  align-items: flex-end;
}

.justify-end {
  justify-content: end;
}

.justify-center {
  justify-content: center;
}
.justify-around {
  justify-content: space-around;
}
.justify-evenly {
  justify-content: space-evenly;
}
.justify-between {
  justify-content: space-between;
}

.bold {
  font-weight: bold;
}

.italic {
  font-style: italic;
}

.bg-cover {
  background-size: cover;
}

.dark {
  background: #212124;
}

.gradient-dark-right {
  background-image: linear-gradient(to right, #212124, transparent);
}

.box-darkness:hover {
  filter: brightness(50%);
}

.darkness {
  filter: brightness(30%);
}

.rounded-full {
  border-radius: 50%;
}

.purple-gradient {
  background: linear-gradient(to top, #5f72bd 0%, #192841 100%);
}

.react-clock {
  border-radius: 2px;
  width: 100%;
  display: flex;
  background-color: white;
}

.border-black {
  border: solid black 1px;
}

.input-border {
  border: solid black 1px;
  border-radius: 2px !important;
}

.input-danger {
  border: solid red 1px;
  border-radius: 2px !important;
  box-shadow: 0 0 0 1px red;
}

.text-danger {
  color: red;
}

.pointer {
  cursor: pointer;
}

.border-none {
  border: none;
}

.rounded {
  border-radius: 4px;
}

.h-input {
  height: 35px;
}

a.nav-link {
  color: #212121;
}

a.active.nav-link {
  font-weight: 600;
  background-color: #39f !important;
  color: #fff !important;
}

.page-item.active .page-link {
  background-color: #39f !important;
  border-color: #39f !important;
}
.page-item.disabled .disabled.page-link {
  background-color: #d3d3d3 !important;
  border-color: #d3d3d3 !important;
  color: #39f;
}
.page-item .page-link {
  color: #39f;
}

.flex-wrap {
  display: flex;
  flex-wrap: wrap;
}

.img-background {
  width: 100%;
  position: absolute;
  bottom: 0;
  top: 0;
  left: 0;
  z-index: -11;
  object-position: top;
  height: 100vh;
  object-fit: cover;
}

.title-loader {
  font-weight: 900;
  color: $yellow;
  position: absolute;
  top: 55%;
  font-size: 2.5em;
  letter-spacing: 8px;
}

.lottie-animation {
  height: 550;
}

.grid-cols-4 {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
}

.gap {
  gap: 4px;
}

// @media (max-width: 1200px) {
//   .box-calendar {
//     grid-template-columns: "1fr 1fr";
//   }
// }

// If you want to add something do it here
@import "custom";
.buttonUpdate {
  width: 100px;
}

@media (max-width: 1500px) {
  .buttonUpdate {
    width: 180px;
    text-align: center;
    margin-left: 5px;
  }
}

@media (max-width: 1175px) {
  .buttonUpdate {
    width: 150px;
    text-align: right;
    margin-left: 8px;
  }
}

@media (max-width: 765px) {
  .buttonUpdate {
    width: fit-content;
  }
}
